import { NextResponse, type NextRequest } from 'next/server';
import { type Session } from 'next-auth';
import { auth } from '@core-systems/auth';
import { replaceHost } from '@core-systems/utils';
import { localeFromRequest } from '../i18n/server/locale/locale-from-request';
import {
  type AllowAction,
  type RedirectAction,
  type MiddlewareAction,
  isAllowAction,
  isRedirectAction,
  isNextAction,
} from './middleware-actions';

/*
 * This module implements a rule-based middleware
 * This middleware either accept the request, either redirects to another page
 * To determine the relevant action, a list of rules is applied
 * The list is evaluated until an "allow" or "redirect" action is encountered
 * If several actions update the cookies, then the updates are combined
 */

export type MiddlewareRuleParameters = {
  currentAppUrl: string;
  currentUrl: string;
  path: string;
  searchParams: Record<string, string>;
  cookies: Record<string, string>;
  headers: Record<string, string>;
  locale: string;
  session: Session | null;
  isServerAction: boolean;
};

export type MiddlewareRule = (params: MiddlewareRuleParameters) => Promise<MiddlewareAction>;

/**
 * This is the core function of the middleware system
 * It evaluates the rules one by one until it encounters an "allow" or "redirect" action
 * It throws an error if no such rule is encountered
 * @param rules - the list of rules to evaluate
 * @param ruleParameters - the parameters sent to every rule
 * @returns an "allow" or "redirect" action
 */
export async function applyMiddlewareRules(
  rules: MiddlewareRule[],
  ruleParameters: MiddlewareRuleParameters,
): Promise<AllowAction | RedirectAction> {
  let setCookies: RedirectAction['setCookies'] = {};
  for (const rule of rules) {
    const action = await rule(ruleParameters);
    if (action.setCookies) setCookies = { ...setCookies, ...action.setCookies }; // combine the cookie updates from every rule

    if (isAllowAction(action) || isRedirectAction(action)) {
      return { ...action, setCookies }; // return the action with combined cookie updates
    }
    if (isNextAction(action)) {
      continue;
    }
    throw new Error(`Unsupported middleware action: ${action}`);
  }
  throw new Error("The middleware rules didn't lead to an 'allow' or 'redirection' action");
}

export async function rulesBasedMiddleware({
  currentAppUrl,
  request,
  rules,
}: {
  currentAppUrl: string;
  request: NextRequest;
  rules: MiddlewareRule[];
}): Promise<NextResponse> {
  // The host of request.nextUrl is wrong in production (http://localhost:3000)
  // Therefore we have to replace it with the actual host
  const currentUrl = replaceHost(request.nextUrl, currentAppUrl).toString();

  const path = request.nextUrl.pathname;
  const locale = localeFromRequest(request);
  const session = await auth();
  const isServerAction = request.method === 'POST' && request.headers.has('Next-Action');

  const searchParamByName: Record<string, string> = {};
  for (const [key, value] of request.nextUrl.searchParams.entries()) {
    searchParamByName[key] = value;
  }

  const cookieByName: Record<string, string> = {};
  for (const cookie of request.cookies.getAll()) {
    cookieByName[cookie.name] = decodeURIComponent(`${cookie.value}`);
  }

  const headerByName: Record<string, string> = {};
  request.headers.forEach((value, key) => {
    headerByName[key] = value;
  });

  const action = await applyMiddlewareRules(rules, {
    currentAppUrl,
    currentUrl,
    path,
    searchParams: searchParamByName,
    cookies: cookieByName,
    headers: headerByName,
    locale,
    session,
    isServerAction,
  });

  const response = isAllowAction(action) ? NextResponse.next() : NextResponse.redirect(action.to);
  if (action.setCookies) {
    for (const [name, { value, options }] of Object.entries(action.setCookies)) {
      response.cookies.set(name, value, options);
    }
  }
  return response;
}
