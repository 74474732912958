import { AuthAction, getAuthConfiguration, LoginMethod, uriToAuth } from '@core-systems/auth';
import { setCookieOnResponse } from '@core-systems/i18n';
import { MiddlewareRule } from './middlewares';
import { urlMatch } from './url';
import { AllowAction, RedirectAction } from './middleware-actions';

// ACTION RULE FACTORIES
// When one of those rules is reached, the next rules are not executed

/**
 * Create a rule that allows the http call
 * @returns a rule that allow the user to access the resource
 */
export function allowIn(): MiddlewareRule {
  const rule: MiddlewareRule = async ({}): Promise<AllowAction> => {
    return { action: 'allow' };
  };
  return rule;
}

/**
 * Create a rule that redirects to a path in the current app
 * @param to - a path to a page (e.g. /en/login)
 * @returns a rule that redirects to the specified path
 */
export function redirectTo(to: string): MiddlewareRule {
  const rule: MiddlewareRule = async ({ currentAppUrl }): Promise<RedirectAction> => {
    const absoluteUrl = new URL(to, currentAppUrl);
    return { action: 'redirect', to: absoluteUrl.toString() };
  };
  return rule;
}

/**
 * Create a rule that redirects to the login page, the current url is passed in the redirectUri search param
 * @param method - the desired login method (with credentials, with Google etc.)
 * @returns a rule that redirects to the login page
 */
export function redirectToLogin(method: LoginMethod): MiddlewareRule {
  const rule: MiddlewareRule = async ({ currentUrl, locale }): Promise<RedirectAction> => {
    const { authAppUrl } = await getAuthConfiguration();

    const uriToLogin = uriToAuth({
      action: AuthAction.Login,
      authAppUrl,
      redirectUri: currentUrl,
      locale,
      method,
    });

    return { action: 'redirect', to: uriToLogin };
  };
  return rule;
}

/**
 * Create a rule that ensures that the url starts with a supported locale
 * @returns a rule that ensures that the url starts with a supported locale
 */
export function ensureUrlHasLocale(): MiddlewareRule {
  const rule: MiddlewareRule = async ({ currentAppUrl, path, searchParams, locale }) => {
    const setCookies = setCookieOnResponse(locale);
    if (!path.startsWith(`/${locale}/`) && path !== `/${locale}`) {
      const url = new URL(`/${locale}${path}`, currentAppUrl);
      for (const [key, value] of Object.entries(searchParams)) {
        url.searchParams.set(key, value);
      }
      return { action: 'redirect', to: url.toString(), setCookies };
    }
    return { action: 'next', setCookies };
  };
  return rule;
}

// CHAIN RULE FACTORIES
// These rules allow to conditionnaly apply other rules

/**
 * Create a rule that apply a rule only if the path matches a pattern
 * @param pattern - a path patten (e.g. /:locale/register)
 * @param ruleFactory - a function that takes the parameters extracted from the path and returns a middleware rule
 * @returns a rule that applies the given rule if the url matches, with its parameters, otherwise switch to the next rule
 */
export function ifPathMatches<T>(pattern: string, ruleFactory: (params: T) => MiddlewareRule): MiddlewareRule {
  const rule: MiddlewareRule = async ({ path, ...args }) => {
    const match = urlMatch<T>(path, pattern);
    if (match) return await ruleFactory(match)({ path, ...args });
    return { action: 'next' };
  };
  return rule;
}

/**
 * Create a rule that apply a rule if the user is authenticated, else another rule
 * @param ifAuthenticated - the rule that's applied if the user is authenticated
 * @param ifNotAuthenticated - the rule that's applied if the user isn't authenticated
 * @returns a rule that applies the ifAuthenticated rule if the user is authenticated, else the ifNotAuthenticated rule
 */
export function checkAuth({
  ifAuthenticated,
  ifNotAuthenticated,
}: {
  ifAuthenticated: MiddlewareRule;
  ifNotAuthenticated: MiddlewareRule;
}): MiddlewareRule {
  const rule: MiddlewareRule = async ({ session, ...args }) => {
    if (session?.isValid) return await ifAuthenticated({ session, ...args });
    return await ifNotAuthenticated({ session, ...args });
  };
  return rule;
}
