import { LoginMethod } from './types/login-method';
import { AuthAction } from './types/auth-action';

export function pathByAction(locale: string | undefined, method: LoginMethod, action: AuthAction): string {
  const basePath = locale ? `/${locale}/${method}` : `/${method}`;

  // This must be kept in sync with the folder structure in apps/auth/src/pages
  switch (action) {
    case AuthAction.ForgotPassword:
      return `${basePath}/forgot-password`;
    case AuthAction.ResetPassword:
      return `${basePath}/reset-password`;
    case AuthAction.Logout:
      return `${basePath}/logout`;
    case AuthAction.ClearSessionLogin:
      return `${basePath}/clear-session-login`;
    case AuthAction.Login:
    default:
      return `${basePath}/login`;
  }
}

export function uriToAuth({
  action,
  authAppUrl,
  locale,
  method = LoginMethod.ANY,
  redirectUri,
}: {
  action: AuthAction;
  authAppUrl: string;
  redirectUri: string;
  locale: string;
  method: LoginMethod;
}): string {
  const loginUrl = new URL(authAppUrl);
  loginUrl.pathname = pathByAction(locale, method, action);
  loginUrl.searchParams.append('redirectUri', redirectUri);
  return loginUrl.toString();
}
