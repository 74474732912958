import NextAuth, { NextAuthResult, type NextAuthConfig, type Session } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import { JWT } from 'next-auth/jwt';
import { decode as jwtDecode } from 'jsonwebtoken';
import { Logger } from '@core-systems/logger';
import { AuthErrorCode } from '../types/auth-error';
import { authorizeCredentials, authorizeGoogleAccessToken } from './authorize';
import { logout } from './logout';
import { isRefreshTokensError, refreshTokens } from './refresh';

/*
 * A short summary of the workflow
 *
 *     When logging in, using the CredentialsProvider (it happens by calling the `signIn` function):
 *       1. The `authorize` callback is called with the credentials, then returns a `user`, without id
 *       2. Next-auth extracts the user id from the access token and adds it to the `user` object
 *       3. The `jwt` callback is called, with the `user` object, a `token` object extracted from the cookie
 *          and an `account` object that states that we've used the "credentials" provider
 *          This callback returns a `token` object augmented with info from the `user` object
 *       4. The `session` callback is called with
 *           * the previous session object extracted from the cookie, if any
 *           * a default session object otherwise
 *          This callback takes the not-too-sensitive info from the `token` object and returns it into the `session` object
 *       5. Next-auth saves the `session` object into the cookie
 *
 *     When using `await auth()` in a server component/middleware:
 *       1. The `jwt` callback is called, with both the `session` and the `token` objects extracted from the cookie
 *          This callback refreshes the `token` object if the access token is expired and the refresh token is still valid
 *          It adds an error code to the token if both tokens are expired
 *          This callback returns the same `token` object, potentially refreshed or with an error code
 *       4. The `session` callback is called with the previous session object extracted from the cookie
 *          This callback takes the not-too-sensitive info from the `token` object and returns it into the `session` object
 *       5. Next-auth saves the `session` object into the cookie and returns it from the `auth` function
 */

export const SESSION_COOKIE_NAME =
  process.env.NODE_ENV === 'production' ? '__Secure-authjs.session-token' : 'authjs.session-token';
const cookiesDomain = process.env.SAFE_DOMAIN;

export const authConfig = {
  providers: [
    CredentialsProvider({
      id: 'credentials',
      name: 'credentials',
      credentials: {
        email: { label: 'Email', type: 'email' },
        password: { label: 'Password', type: 'password' },
      },
      type: 'credentials',
      authorize: authorizeCredentials,
    }),
    CredentialsProvider({
      id: 'google',
      name: 'google',
      credentials: {
        accessToken: { label: 'Access token', type: 'password' },
      },
      type: 'credentials',
      authorize: authorizeGoogleAccessToken,
    }),
  ],
  session: {
    strategy: 'jwt',
  },
  cookies: {
    sessionToken: { name: SESSION_COOKIE_NAME, options: { domain: cookiesDomain } },
    callbackUrl: { options: { domain: cookiesDomain } },
    csrfToken: { options: undefined },
    pkceCodeVerifier: { options: { domain: cookiesDomain } },
    state: { options: { domain: cookiesDomain } },
    nonce: { options: { domain: cookiesDomain } },
    webauthnChallenge: { options: { domain: cookiesDomain } },
  },
  callbacks: {
    signIn: async (args): Promise<boolean> => {
      /**
       * Controls whether a user is allowed to sign in or not.
       * Returning `true` continues the sign-in flow.
       * Returning `false` or throwing an error will stop the sign-in flow and redirect the user to the error page.
       * Returning a string will redirect the user to the specified URL.
       *
       * Unhandled errors will throw an `AccessDenied` with the message set to the original error.
       *
       * [`AccessDenied`](https://authjs.dev/reference/core/errors#accessdenied)
       *
       * @example
       * ```ts
       * callbacks: {
       *  async signIn({ profile }) {
       *   // Only allow sign in for users with email addresses ending with "yourdomain.com"
       *   return profile?.email?.endsWith("@yourdomain.com")
       * }
       * ```
       */
      const { user } = args;
      return Boolean(user.id);
    },
    jwt: async (args): Promise<JWT> => {
      /**
       * This callback is called whenever a JSON Web Token is created (i.e. at sign in)
       * or updated (i.e whenever a session is accessed in the client). Anything you
       * return here will be saved in the JWT and forwarded to the session callback.
       * There you can control what should be returned to the client. Anything else
       * will be kept from your frontend. The JWT is encrypted by default via your
       * AUTH_SECRET environment variable.
       *
       * [`jwt` callback](https://authjs.dev/reference/core/types#jwt)
       */
      const { token, user, account } = args;
      const now = Math.floor(Date.now() / 1000); // Timestamp in seconds
      if (account && user) {
        return {
          ...token,
          accessToken: user.accessToken,
          accessTokenExpiresAt: now + user.accessTokenExpiresIn,
          refreshToken: user.refreshToken,
          refreshTokenExpiresAt: now + user.refreshTokenExpiresIn,
          error: undefined,
        };
      }

      if (now < token.accessTokenExpiresAt) {
        // The access token is still valid, let's keep it
        return token;
      }

      if (token.refreshTokenExpiresAt < now) {
        // The refresh token is expired, the session is over
        return { ...token, error: AuthErrorCode.AUTH_REFRESH_TOKEN_EXPIRED }; // the old tokens are kept but are invalid
      }

      // The refresh token is still valid, let's use it to generate a new pair of tokens
      const newTokens = await refreshTokens(token.refreshToken);
      if (isRefreshTokensError(newTokens)) {
        return { ...token, error: newTokens.error }; // the old tokens are kept but are invalid
      }

      return {
        ...token,
        accessToken: newTokens.accessToken,
        accessTokenExpiresAt: now + newTokens.expiresIn,
        refreshToken: newTokens.refreshToken,
        refreshTokenExpiresAt: now + newTokens.refreshExpiresIn,
      };
    },
    session: async (args): Promise<Session> => {
      /**
       * This callback is called whenever a session is checked.
       * (i.e. when invoking the `/api/session` endpoint, using `useSession` or `getSession`).
       * The return value will be exposed to the client, so be careful what you return here!
       * If you want to make anything available to the client which you've added to the token
       * through the JWT callback, you have to explicitly return it here as well.
       *
       * :::note
       * ⚠ By default, only a subset (email, name, image)
       * of the token is returned for increased security.
       * :::
       *
       * The token argument is only available when using the jwt session strategy, and the
       * user argument is only available when using the database session strategy.
       *
       * [`session` callback](https://authjs.dev/reference/core/types#session)
       *
       * @example
       * ```ts
       * callbacks: {
       *   async session({ session, token, user }) {
       *     // Send properties to the client, like an access_token from a provider.
       *     session.accessToken = token.accessToken
       *
       *     return session
       *   }
       * }
       * ```
       */
      // Here `token` is the output of the `jwt` callback
      const { session, token } = args;
      const now = Math.floor(Date.now() / 1000); // Timestamp in seconds
      const cantRefresh = Boolean(token.error);
      const isValid = now < token.accessTokenExpiresAt && !cantRefresh;
      const accessToken: any = jwtDecode(token.accessToken);
      return {
        ...session,
        user: {
          ...session.user,
          id: accessToken.preferred_username,
          email: accessToken.email,
          campuses: accessToken.campuses,
        },
        expires: new Date(token.refreshTokenExpiresAt * 1000).toISOString(),
        accessToken: token.accessToken,
        isValid,
        isExpired: cantRefresh,
      };
    },
  },
  events: {
    signOut: async (args: any): Promise<void> => {
      if (args.token) {
        const tokens = args.token as JWT;
        const refreshToken = tokens.refreshToken;
        if (refreshToken) {
          try {
            await logout(refreshToken);
          } catch (error) {
            // This can happen if the refresh token is invalid, in that case there is no need to do anything
            // But log the error in case there is something wrong going on
            new Logger('auth.events.signOut').error(JSON.stringify(error));
          }
        }
      }
    },
  },
} satisfies NextAuthConfig;

export const nextAuth = NextAuth(authConfig);

export const auth: NextAuthResult['auth'] = nextAuth.auth;
export const handlers: NextAuthResult['handlers'] = nextAuth.handlers;
export const signIn: NextAuthResult['signIn'] = nextAuth.signIn;
export const signOut: NextAuthResult['signOut'] = nextAuth.signOut;
