import { obfuscate } from '../utils/obfuscate';

enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
}

/**
 * Logger implements winston logger.
 *
 *
 * Log levels from winston: https://github.com/winstonjs/winston?tab=readme-ov-file#logging-levels
 * Log levels available in API: error, warn, info, verbose, debug, silly
 */
export class Logger {
  private context: string | null = null;

  constructor(context: string = '') {
    if (context) {
      this.context = context;
    }
  }

  get logLevel(): LogLevel {
    const level = process.env.LOG_LEVEL;

    if (!level || !Object.values<string>(LogLevel).includes(level)) {
      return LogLevel.INFO;
    }

    return level as LogLevel;
  }

  private format(severity: LogLevel, message: string, ...meta: any[]): string {
    return JSON.stringify({
      timestamp: Date.now(),
      severity,
      context: this.context,
      message,
      meta: obfuscate(meta),
    });
  }

  private metaMapper(meta: any[]): unknown[] {
    return meta.map((value, index) => {
      if (value instanceof Error) {
        return {
          error: value.message,
          stack: value.stack,
        };
      }

      if ([null, undefined].includes(value)) {
        return { [index]: value };
      }

      if (['number', 'string'].includes(typeof value)) {
        return { [index]: value };
      }

      return value;
    });
  }

  private log(severity: LogLevel, message: string, meta: unknown[]): void {
    const formattedMessage = this.format(severity, message, ...this.metaMapper(meta));

    // eslint-disable-next-line no-console
    console[severity](formattedMessage);
  }

  setContext(context: string): void {
    this.context = context;
  }

  error(message: string, ...meta: unknown[]): void {
    this.log(LogLevel.ERROR, message, meta);
  }

  warn(message: string, ...meta: unknown[]): void {
    this.log(LogLevel.WARN, message, meta);
  }

  info(message: string, ...meta: unknown[]): void {
    this.log(LogLevel.INFO, message, meta);
  }
}
